import { Fragment, useState } from 'react';
import { Nav, Row } from 'react-bootstrap';
import { work } from '../../data/data.tsx';
import { WorkItem } from './WorkItem.js';

export const Work = ({ light }) => {
  const [activeKey, setActiveKey] = useState('websites');

  const handleSelect = (e) => {
    setActiveKey(e);
  };

  return (
    <Fragment>
      <h4 className='work-heading'>What have I done and what am I able to do?</h4>
      <hr />
      <Nav variant='pills' className='nav-works justify-content-center' activeKey={activeKey} onSelect={handleSelect}>
        {/* <Nav variant='pills' className='nav-works justify-content-center'> */}
        <Nav.Item>
          <Nav.Link eventKey='websites'>Websites</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey='applications'>Applications</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey='3dModelling'>3D Modelling</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey='graphicsDesign'>Graphics Design</Nav.Link>
        </Nav.Item>
      </Nav>

      <Row
        xs={1}
        className={`row-works g-4${work[activeKey][0]?.tallImage ? ' img-tall' : ''}${
          work[activeKey][0]?.model || work[activeKey][0]?.imageModel ? ' model-3d' : ''
        }${work[activeKey][0]?.svgImage ? ' img-svg' : ''}`}
      >
        {work[activeKey].map((w, i) => (
          <WorkItem key={w.title} w={w} i={i} light={light} />
        ))}
      </Row>
    </Fragment>
  );
};
