import { Card, Col, Row } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';

export const PortfolioLink = (props) => {
  const { ref, inView } = useInView({ threshold: 0.3, triggerOnce: true });

  return (
    <div className={`portfolio-container${inView ? ' in-view' : ''}`} ref={ref}>
      <h2 className='portfolio-heading'>Have a look at what I've done</h2>
      <p className='portfolio-affirmation'>Click the link below to view some of the work I have done!</p>
      <hr />
      <Row xs={1} className='portfolio-row g-4'>
        <Col className='portfolio-col'>
          <Card className='portfolio-card'>
            <a href='/portfolio'>
              <Card.Body>
                <i className='bi bi-person-workspace icon-workspace' />
                View Portfolio
              </Card.Body>
            </a>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
