import website1 from '../images/website-1.png';
import website2 from '../images/website-2.png';

import ticTacToe1 from '../images/tic-tac-toe-c#.png';
import ticTacToe2 from '../images/tic-tac-toe-c#-2.png';

import pouch from '../3dmodels/pouch.gltf';
import pouchUvs from '../images/pouch-uvs.png';

import packageTemplate from '../images/package-template.png';
import { IconsImage } from '../components/Home/IconsImage.js';
import { FC } from 'react';

import calculator500 from '../images/Calculator-500.png';
import notes500 from '../images/notes-500.png';
import ticTacToe500 from '../images/tic-tac-toe-500.png';
import bankingApp500 from '../images/banking-app-500.png';
import website500 from '../images/website-500.png';
import model500 from '../images/model-500.png';
import package500 from '../images/package-500.png';

interface workModel {
  websites: Array<workObject>;
  applications: Array<workObject>;
  '3dModelling': Array<workObject>;
  graphicsDesign: Array<workObject>;
}

interface workObject {
  title: string;
  description: string;
  tooltip?: string;
  image?: string;
  tallImage?: boolean;
  model?: string;
  imageModel?: boolean;
  svg?: FC;
  svgImage?: boolean;
}

interface exampleObject {
  title: string;
  description: string;
  image: string | FC;
  gitHubUrl?: string;
  svg?: boolean;
  tooltip?: string;
}

export const work: workModel = {
  websites: [
    {
      title: 'Frontend and Backend',
      description: "Frontend design allows a website to attract it's target market, whereas backend allows a website to be dynamic.",
      image: website1,
    },
    {
      title: 'Transitions and Multipaged Websites',
      description:
        'Translations, opacity changes, colouring and more allows for a greater appeal to the website. Multiple pages allow for a better user experience.',
      image: website2,
    },
  ],
  applications: [
    {
      title: 'Windows Applications',
      description: 'Windows applications that can be uploaded to Microsoft store and downloaded by you and others.',
      image: ticTacToe1,
      tallImage: true,
    },
    {
      title: 'Windows Games',
      description: 'Certain games can include an AI that makes it either easy, difficult, or impossible to win.',
      image: ticTacToe2,
      tallImage: true,
    },
  ],
  '3dModelling': [
    {
      title: '3D Objects',
      description: '3D Objects can be used in various scenarios such as the images on a website and for augmented reality applications.',
      model: pouch,
    },
    {
      title: 'And Their UVs',
      description: '3D Objects have UVs that allow you to place a 2D image onto a 3D object.',
      image: pouchUvs,
      imageModel: true, // used to allow both objects to have same properties
    },
  ],
  graphicsDesign: [
    {
      title: 'Icons and Logos',
      description: 'Icons and Logos for your business and websites that attract customers.',
      svg: IconsImage,
      svgImage: true,
    },
    {
      title: 'Package Design',
      description: 'If your business requires packages, a 2D design of a package can be printed and folded into a 3D box.',
      tooltip: 'Cookies PNGs by Vecteezy. Barcode image by rawpixel.com on Freepik.',
      image: packageTemplate,
      svgImage: true,
    },
  ],
};

export const testimonials = [
  {
    content: 'Casey has been amazing to work with, he is responsive, quick and professional in his work. I can always rely on him to get the job done.',
    user: 'SoloCord',
  },
  {
    content: 'Casey has made a Discord bot for me and was efficient with his time taken to get the job done.',
    user: 'ChrisR',
  },
  {
    content: "I've worked with Casey on small projects as his leader and as far as I am concerned, he was very helpful in completing the tasks at hand.",
    user: 'Ezier',
  },
];

export const portfolioExamples: Array<exampleObject> = [
  {
    title: 'Calculator in C#',
    description: 'I built a calculator similar to that of the Windows calculator in C#.',
    image: calculator500,
    gitHubUrl: 'https://github.com/Betanoir/Calculator-in-C-Sharp',
  },
  {
    title: 'Notes in C#',
    description: 'I attempted a simple replica of the Samsung Notes application in C#.',
    image: notes500,
    gitHubUrl: 'https://github.com/Betanoir/Notes-in-C-Sharp',
  },
  {
    title: 'Tic-Tac-Toe with AI',
    description: 'I built a game of tic-tac-toe that does not allow you to win by use of the MiniMax algoithm with alpha-beta pruning.',
    image: ticTacToe500,
    gitHubUrl: 'https://github.com/Betanoir/Tic-Tac-Toe-in-C-Sharp-with-MiniMax-Algorithm',
  },
  {
    title: 'Banking App in C#',
    description: 'I designed a mock of a banking app in C#. You can do all the usual things you would do on an actual banking application.',
    image: bankingApp500,
    gitHubUrl: 'https://github.com/Betanoir/BankingApp-in-C-Sharp',
  },
  {
    title: 'Ecommerce Website',
    description: 'I designed an ecommerce website for a client using the Nest.js framework.',
    image: website500,
  },
  {
    title: '3D Model and UVs',
    description: 'I modelled this doy pouch for a client.',
    image: model500,
  },
  {
    title: 'Package Design',
    description: "Using ChatGPT, I generated some dummy information for a fake business and designed a package for the business' products.",
    image: package500,
    tooltip: 'Cookies PNGs by Vecteezy. Barcode image by rawpixel.com on Freepik.',
  },
  {
    title: 'Icons',
    description: 'I designed these icons for my dummy business package design.',
    image: IconsImage,
    svg: true,
  },
];
