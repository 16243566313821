import { Suspense } from 'react';
import { Col } from 'react-bootstrap';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader, Canvas } from '@react-three/fiber';
import { Environment, Html, OrbitControls, useProgress } from '@react-three/drei';
import { useInView } from 'react-intersection-observer';
import { InfoTooltip } from '../Utilities/InfoTooltip';

const Loader = () => {
  const { progress } = useProgress();
  return <Html center>{progress.toPrecision(2)}% loaded</Html>;
};

// export default function ModelLoader({ gltf }) {
//   const groupRef = useRef();
//   const { nodes, materials } = useGLTF(gltf);
//   return (
//     <group ref={groupRef} gltf={gltf} dispose={null}>
//       <mesh castShadow receiveShadow geometry={nodes.Curve007_1.geometry} material={materials['Material.001']} />
//       <mesh castShadow receiveShadow geometry={nodes.Curve007_2.geometry} material={materials['Material.002']} />
//     </group>
//   );
// }

const ModelLoader = ({ gltf }) => {
  const object = useLoader(GLTFLoader, gltf);

  return <primitive object={object.scene} scale={18} position={[0, -2.1, 0]} rotation={[0, 80, 0]} />;
};

const Model = ({ gltf }) => (
  <Canvas className='model-canvas'>
    <Suspense fallback={<Loader />}>
      {/* <ambientLight intensity={1} /> */}
      {/* <pointLight color='#fff' position={[10, 10, 10]} intensity={5} /> */}
      <ModelLoader gltf={gltf} />
      <OrbitControls />
      <Environment blur={1} preset='sunset' background />
    </Suspense>
  </Canvas>
);

export const WorkItem = ({ w, i, light }) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  return (
    // <Col
    //   ref={ref}
    //   className={
    //     `work-item-container` +
    //     `${inView ? ' in-view' : ''}` +
    //     `${i % 2 === 0 ? ' img-right' : ''}` +
    //     `${w.tallImage ? ' img-tall' : ''}` +
    //     `${!!w.model || w.imageModel ? ' model-3d' : ''}` +
    //     `${w.svgImage ? ' img-svg' : ''}`
    //   }
    // >
    <Col
      ref={ref}
      className={
        `work-col` +
        `${inView ? ' in-view' : ''}` +
        `${i % 2 === 0 ? ' img-right' : ''}` +
        `${w.tallImage ? ' img-tall' : ''}` +
        `${!!w.model || w.imageModel ? ' model-3d' : ''}` +
        `${w.svgImage ? ' img-svg' : ''}`
      }
    >
      {!!w.svg && <w.svg light={light} />}
      {!!w.image && <img src={w.image} alt='Describes the work.' className='work-img' />}
      {!!w.model && <Model gltf={w.model} />}
      <div className='work-details'>
        <h3>{w.title}</h3>
        <hr />
        <p>
          {w.description} {!!w.tooltip && <InfoTooltip light={light} text={w.tooltip} textAlign='left' />}
        </p>
      </div>
    </Col>
    // </Col>
  );
};
