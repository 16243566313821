import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Contact, Footer, TOSModal, PortfolioHeader, PortfolioExamples } from '../components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
// import { createPortal } from "react-dom";
// import useWindowDimensions from "../helpers/useWindowDimensions";

export const Portfolio = (props) => {
  //const { width } = useWindowDimensions(); // returns height and width
  const [showTos, setShowTos] = useState(false);

  const handleTosClick = () => setShowTos((currState) => !currState);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Casey Harper – Portfolio' });
  });

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Casey Harper &ndash; Portfolio</title>
      </Helmet>
      <TOSModal light={props.light} showTos={showTos} setShowTos={setShowTos} />
      {!!props.alert?.content && (
        <Alert variant={props.alert.colour} className='alert-home'>
          <div className='bi bi-info-circle alert-icon' />
          {props.alert.content}
        </Alert>
      )}

      <section id='pricing-header'>
        <PortfolioHeader light={props.light} changeTheme={props.changeTheme} alert={alert} />
      </section>

      <section id='portfolio-examples'>
        <PortfolioExamples light={props.light} />
      </section>

      <section id='contact'>
        <Contact light={props.light} cellNumber={props.cellNumber} />
      </section>

      <section id='footer'>
        <Footer light={props.light} tos={handleTosClick} />
      </section>
    </HelmetProvider>
  );
};
