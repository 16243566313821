import { Row, Col } from 'react-bootstrap';
import websitePricingImage from '../../images/websites-pricing-image.png';
import applicationPricingImage from '../../images/applications-pricing-image.png';
import modelsPricingImage from '../../images/3d-models-pricing-image.png';
import graphicsPricingImage from '../../images/graphics-design-pricing-image.png';
import { useInView } from 'react-intersection-observer';
import { InfoTooltip } from '..';

export const Prices = (props) => {
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });

  return (
    <div className='prices-container'>
      <Row xs={1} md={2} xxl={4} className='row-prices g-4 text-start'>
        <Col className={`price-col${inView1 ? ' in-view' : ''}`} ref={ref1}>
          <div className='price-details'>
            <img className='price-img' src={websitePricingImage} alt='Website Pricing Example' />
            <h3 className='text-center'>Websites</h3>
            <hr />
            <p className='prices-start-at'>
              Starting at <span className='price-number'>R999</span>
            </p>

            <div className='items-container'>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>Your website
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>
                Database connections <InfoTooltip light={props.light} text='The database needs to be paid for either in full or monthly by the client.' />
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>Backend development
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>Multiple pages{' '}
                <InfoTooltip light={props.light} text='The website will only contain multiple pages if you request that it should.' />
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>
                Domain setup <InfoTooltip light={props.light} text='The domains need to be paid for by the client.' />
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>
                Taking the website online{' '}
                <InfoTooltip
                  light={props.light}
                  text='The server, whether it be a VPS (Virtual Private Server) or a local server, needs to be paid for by the client.'
                />
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>And a confounding website!
              </p>
            </div>

            <div className='price-info'>
              <p className='important-info'>
                All websites are priced based on an hourly rate. For more information, view my{' '}
                <span className='tos-button' onClick={props.tos}>
                  Terms of Service
                </span>
                .
              </p>
              <button className='btn-price-contact'>
                <a href={`https://wa.me/${props.cellNumber}`} target='_blank' rel='noreferrer'>
                  <i className='bi bi-whatsapp whatsapp-icon' /> CONTACT ME
                </a>
              </button>
            </div>
          </div>
        </Col>
        <Col className={`price-col${inView2 ? ' in-view' : ''}`} ref={ref2}>
          <div className='price-details'>
            <img className='price-img' src={applicationPricingImage} alt='Application Pricing Example' />
            <h3 className='text-center'>Applications</h3>
            <hr />
            <p className='prices-start-at'>
              Starting at <span className='price-number'>R999</span>
            </p>

            <div className='items-container'>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>Your application
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>
                Connections to a database <InfoTooltip light={props.light} text='The database needs to be paid for either in full or monthly by the client.' />
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>And an astounding application!
              </p>
            </div>

            <div className='price-info'>
              <p className='important-info'>
                All applications are priced based on an hourly rate. For more information, view my{' '}
                <span className='tos-button' onClick={props.tos}>
                  Terms of Service
                </span>
                .
              </p>
              <button className='btn-price-contact'>
                <a href={`https://wa.me/${props.cellNumber}`} target='_blank' rel='noreferrer'>
                  <i className='bi bi-whatsapp whatsapp-icon' /> CONTACT ME
                </a>
              </button>
            </div>
          </div>
        </Col>
        <Col className={`price-col${inView3 ? ' in-view' : ''}`} ref={ref3}>
          <div className='price-details'>
            <img className='price-img' src={modelsPricingImage} alt='3D Models Pricing Example' />
            <h3 className='text-center'>3D Modelling</h3>
            <hr />
            <p className='prices-start-at'>
              Starting at <span className='price-number'>R499</span>
            </p>

            <div className='items-container'>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>The 3D model
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>
                The UVs{' '}
                <InfoTooltip
                  light={props.light}
                  text='The UV image of the model will be fully straightened and sized in accordance with any measurements provided.'
                />
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>
                Image textures{' '}
                <InfoTooltip light={props.light} text='Image textures will be added to the model, but the image textures need to be provided by the client.' />
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>And a breathtaking 3D Model!
              </p>
            </div>

            <div className='price-info'>
              <p className='important-info'>
                All 3D models are priced based on an hourly rate. For more information, view my{' '}
                <span className='tos-button' onClick={props.tos}>
                  Terms of Service
                </span>
                .
              </p>
              <button className='btn-price-contact'>
                <a href={`https://wa.me/${props.cellNumber}`} target='_blank' rel='noreferrer'>
                  <i className='bi bi-whatsapp whatsapp-icon' /> CONTACT ME
                </a>
              </button>
            </div>
          </div>
        </Col>
        <Col className={`price-col${inView4 ? ' in-view' : ''}`} ref={ref4}>
          <div className='price-details'>
            <img className='price-img' src={graphicsPricingImage} alt='Graphics Design Pricing Example' />
            <h3 className='text-center'>Graphics Design</h3>
            <hr />
            <p className='prices-start-at'>
              Starting at <span className='price-number'>R249</span>
            </p>

            <div className='items-container'>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>
                Your icons, logos or labels{' '}
                <InfoTooltip light={props.light} text='All icons, logos, labels, etcetera will be created in accordance with any measurements provided.' />
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>
                Your business packaging{' '}
                <InfoTooltip light={props.light} text='Dielines need to be provided for measurements and layout of the package design.' />
              </p>
              <p className='item-included'>
                <i class='bi bi-check-lg check-icon'></i>And a perplexing design!
              </p>
            </div>

            <div className='price-info'>
              <p className='important-info'>
                All designs are priced based on an hourly rate. For more information, view my{' '}
                <span className='tos-button' onClick={props.tos}>
                  Terms of Service
                </span>
                .
              </p>
              <button className='btn-price-contact'>
                <a href={`https://wa.me/${props.cellNumber}`} target='_blank' rel='noreferrer'>
                  <i className='bi bi-whatsapp whatsapp-icon' /> CONTACT ME
                </a>
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
