import { Fragment, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { HomeHeader, Work, Testimonials, Contact, Footer, TOSModal, PricingLink, PortfolioLink } from '../components';
import ReactGA from 'react-ga4';
// import { createPortal } from "react-dom";
// import useWindowDimensions from "../helpers/useWindowDimensions";

export const Home = (props) => {
  //const { width } = useWindowDimensions(); // returns height and width
  const [showTos, setShowTos] = useState(props.tos ?? false);

  const handleTosClick = () => setShowTos((currState) => !currState);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Casey Harper – Homepage' });

    if (showTos) window.history.replaceState(null, '', '/'); // if a person locates to /tos, change the path from /tos to /
  });

  return (
    <Fragment>
      <TOSModal light={props.light} showTos={showTos} setShowTos={setShowTos} />
      {!!props.alert?.content && (
        <Alert variant={props.alert.colour} className='alert-home'>
          <div className='bi bi-info-circle alert-icon' />
          {props.alert.content}
        </Alert>
      )}
      <section id='header'>
        <HomeHeader light={props.light} changeTheme={props.changeTheme} alert={alert} />
      </section>

      <section id='work'>
        <Work light={props.light} />
      </section>

      <section id='testimonials'>
        <Testimonials light={props.light} />
      </section>

      {/* Table prop will determine whether to display a table or cards on the display */}
      <section id='pricing-link'>
        <PricingLink light={props.light} tos={handleTosClick} />
      </section>

      <section id='portfolio-link'>
        <PortfolioLink light={props.light} tos={handleTosClick} />
      </section>

      <section id='contact' className='contact-home'>
        <Contact light={props.light} cellNumber={props.cellNumber} />
      </section>

      <section id='footer' className='footer-home'>
        <Footer light={props.light} tos={handleTosClick} />
      </section>
    </Fragment>
  );
};
