import { Fragment } from 'react';
import { Alert, Row } from 'react-bootstrap';
import { portfolioExamples } from '../../data/data';
import { PortfolioExample } from '..';
import { useInView } from 'react-intersection-observer';

export const PortfolioExamples = ({ light }) => {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <Fragment>
      <Alert variant='info' className={`example-alert text-start${inView ? ' in-view' : ''}`} ref={ref}>
        <Alert.Heading>Sorry...</Alert.Heading>
        <p>
          I, unfortunately, cannot provide GitHub links to any websites I have created due to the risks that come with making their repositories publicly
          accessible. However, the website you are looking at currently was made entirely by me, Casey Harper.
        </p>
      </Alert>
      <Row xs={1} md={2} xxl={4} className='text-start g-4 row-examples'>
        {portfolioExamples.map((p, i) => (
          <PortfolioExample light={light} p={p} key={i} />
        ))}
      </Row>
    </Fragment>
  );
};
