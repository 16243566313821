import { Card, Col, Row } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';

export const PricingLink = (props) => {
  const { ref, inView } = useInView({ threshold: 0.3, triggerOnce: true });

  return (
    <div className={`pricing-container${inView ? ' in-view' : ''}`} ref={ref}>
      <h2 className='pricing-heading'>What Are My Prices?</h2>
      <p className='pricing-affirmation'>Click the link below and you will be redirected to the prices page of my website!</p>
      <hr />
      <Row xs={1} className='pricing-row g-4'>
        <Col className='pricing-col'>
          <Card className='pricing-card'>
            <a href='/pricing'>
              <Card.Body>
                <i className='bi bi-shop icon-shop' />
                View Prices
              </Card.Body>
            </a>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
