import { Card, Col, Row } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';

export const Contact = (props) => {
  const {ref, inView} = useInView({ threshold: 0.3, triggerOnce: true });
  return (
    <div className={`contact-container${inView ? ' in-view' :''}`} ref={ref}>
      <h2 className='contact-heading'>I'd Love to Hear From You</h2>
      <p className='contact-affirmation'>
        Whether you're curious about features, process, or purchasing a product&mdash;I'm ready to answer any and all of your questions.
      </p>
      <hr />
      <Row xs={1} lg={2} className='contact-row g-4'>
        <Col className='contact-col'>
          <Card className='contact-card'>
            <a target='_blank' rel='noreferrer' href={`https://wa.me/${props.cellNumber}`}>
              <Card.Body>
                <i className='bi bi-whatsapp icon-whatsapp' />
                Contact me on WhatsApp
              </Card.Body>
            </a>
          </Card>
        </Col>
        <Col className='contact-col'>
          <Card className='contact-card'>
            <a target='_blank' rel='noreferrer' href='https://www.instagram.com/casehrpr'>
              <Card.Body>
                <i className='bi bi-instagram icon-instagram' />
                Contact me on Instagram
              </Card.Body>
            </a>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
