import { Col, ListGroup, Row } from 'react-bootstrap';

export const Footer = (props) => {
  return (
    <Row xs={1} md={2} className='footer-row g-4'>
      <Col className='footer-left footer-col'>
        <h3 className='footer-heading'>Casey Harper</h3>
        <p>Casey Harper &copy; {new Date().getFullYear()}</p>
      </Col>
      <Col className='footer-right footer-col'>
        <ListGroup>
          <ListGroup.Item className='footer-list-item'>
            <p onClick={props.tos}>Terms of Service</p>
          </ListGroup.Item>
          <ListGroup.Item className='footer-list-item'>
            <a rel='noreferrer' href='pricing'>
              Pricing
            </a>
          </ListGroup.Item>
          <ListGroup.Item className='footer-list-item'>
            <a rel='noreferrer' href='portfolio'>
              Portfolio
            </a>
          </ListGroup.Item>
        </ListGroup>
      </Col>
    </Row>
  );
};
