import { Fragment } from 'react';

export const PortfolioHeader = (props) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') props.changeTheme();
  };

  return (
    <Fragment>
      <div className='container-fluid title-container'>
        <nav className='nav-bar'>
          <a href='/' className='nav-item'>
            Home
          </a>
          <a href='/pricing' className='nav-item'>
            Pricing
          </a>
          <a href='#contact' className='nav-item'>
            Contact
          </a>
          <div
            className={`bi ${props.light ? 'bi-moon-fill' : 'bi-sun-fill'} toggle-theme-icon nav-item`}
            onClick={props.changeTheme}
            onKeyDown={handleKeyDown}
            tabIndex='0'
          />
        </nav>
        <div className='name-box'>
          <h1 className='name'>Hi, I'm Casey</h1>
          <h1 className='pricing-title'>Here are some examples of my work:</h1>
        </div>
      </div>
    </Fragment>
  );
};
