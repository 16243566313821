import React, { ReactNode, useState } from 'react';

interface TooltipProps {
  text: string;
  light: boolean;
  children: ReactNode;
  delay?: number;
  direction?: 'top' | 'bottom' | 'left' | 'right';
  textAlign?: 'left' | 'center' | 'right';
}

export const InfoTooltip = ({ text, light, delay, direction, children, textAlign }: TooltipProps) => {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState<boolean>(false);

  const showTooltip = () => {
    timeout = setTimeout(() => {}, delay ?? 100);
    setActive(true);
  };

  const hideToolTip = () => {
    clearTimeout(timeout);
    setActive(false);
  };

  return (
    <span className='tooltip-personal-wrapper' onMouseEnter={showTooltip} onMouseLeave={hideToolTip}>
      {children ?? <i className='bi bi-info-circle'></i>}
      {active && (
        <span className={`tooltip-personal ${direction || 'top'} ${light ? 'light' : 'dark'} ${textAlign ? `align-${textAlign}` : 'align-center'}`}>
          {text}
        </span>
      )}
    </span>
  );
};
