import { Fragment } from 'react';

export const PricingHeader = (props) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') props.changeTheme();
  };

  return (
    <Fragment>
      <div className='container-fluid prices-title-container'>
        <nav className='nav-bar'>
          <a href='/' className='nav-item'>
            Home
          </a>
          <a href='/portfolio' className='nav-item'>
            Portfolio
          </a>
          <a href='#contact' className='nav-item'>
            Contact
          </a>
          <div
            className={`${props.light ? 'bi-moon-fill' : 'bi-sun-fill'} toggle-theme-icon nav-item`}
            onClick={props.changeTheme}
            onKeyDown={handleKeyDown}
            tabIndex='0'
          />
        </nav>
        <div className='name-box'>
          <h1 className='name'>Hi, I'm Casey</h1>
          <h1 className='pricing-title'>What are my prices?</h1>
        </div>

        <div className='arrow-container'>
          <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='currentColor' class='bi bi-chevron-double-down inf-arrows' viewBox='0 0 16 16'>
            <path
              fill-rule='evenodd'
              d='M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
            />
            <path
              fill-rule='evenodd'
              d='M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
            />
          </svg>
        </div>
      </div>
    </Fragment>
  );
};
