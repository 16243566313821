import { Fragment } from 'react';

export const HomeHeader = (props) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') props.changeTheme();
  };

  return (
    <Fragment>
      <div className='container-fluid title-container'>
        <nav className='nav-bar'>
          <a href='/pricing' className='nav-item'>
            Pricing
          </a>
          <a href='/portfolio' className='nav-item'>
            Portfolio
          </a>
          <a href='#contact' className='nav-item'>
            Contact
          </a>
          <div
            className={`${props.light ? 'bi-moon-fill' : 'bi-sun-fill'} toggle-theme-icon nav-item`}
            onClick={props.changeTheme}
            onKeyDown={handleKeyDown}
            tabIndex='0'
          />
        </nav>
        <div className='name-box'>
          <h1 className='name'>Hi, I'm Casey.</h1>
          <p className='description'>A full stack web developer, software developer, 3d modeller and graphics designer.</p>
        </div>
        {/* {<CircleTree />}
        {<CircleTree right />} */}
      </div>
    </Fragment>
  );
};
