import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Footer, TOSModal } from '../components';
import ReactGA from 'react-ga4';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const Error404 = (props) => {
  const [showTos, setShowTos] = useState(props.tos ?? false);

  const handleTosClick = () => setShowTos((currState) => !currState);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Casey Harper – Homepage' });
  });

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet='utf-8' />
        <title>404 &ndash; Casey Harper</title>
      </Helmet>
      <TOSModal light={props.light} showTos={showTos} setShowTos={setShowTos} />
      <div className='error-404-container'>
        <div className='error-404-inner'>
          <div className='error-404-message-box'>
            <h1 className='error-404-heading'>
              404 <span>&ndash; This page does not exist.</span>
            </h1>
            <p className='error-404-message'>Click Home, Portfolio, or Pricing to be set back on track!</p>
          </div>
          {/* <div className='error-404-button-box'> */}
          <Row xs={1} className='button-row g-4'>
            <Col className='button-col'>
              <Card className='button-card'>
                <a href='/'>
                  <Card.Body>
                    <i className='bi bi-house-up' />
                    Home
                  </Card.Body>
                </a>
              </Card>
            </Col>
            <Col className='button-col'>
              <Card className='button-card'>
                <a href='/portfolio'>
                  <Card.Body>
                    <i className='bi bi-person-workspace' />
                    Portfolio
                  </Card.Body>
                </a>
              </Card>
            </Col>
            <Col className='button-col'>
              <Card className='button-card'>
                <a href='/pricing'>
                  <Card.Body>
                    <i className='bi bi-shop' />
                    Pricing
                  </Card.Body>
                </a>
              </Card>
            </Col>
          </Row>
          {/* </div> */}
        </div>
      </div>
      <section id='footer'>
        <Footer light={props.light} tos={handleTosClick} />
      </section>
    </HelmetProvider>
  );
};
