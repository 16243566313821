import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, Portfolio, Pricing, Error404 } from './pages';
import useHomeAlert from './helpers/homeAlert';
import ReactGA from 'react-ga4';
import { socket } from './helpers/socket';

import './stylesheets/general.scss';
import './stylesheets/home.scss';
import './stylesheets/pricing.scss';
import './stylesheets/portfolio.scss';
import './stylesheets/404.scss';

import './stylesheets/light-design.scss';
import './stylesheets/dark-design.scss';
import './stylesheets/media-queries.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Birthday } from './pages/Birthday';

const cellNumber = '+27638230064'; // ommited the +27 as it is already in the string

export default function App() {
  const URL = process.env.NODE_ENV === 'production' ? window.location.origin + '/api' : 'http://localhost:3001/api';
  const [light, setLight] = useState(false);
  const [alert, setAlert] = useHomeAlert(URL);
  const TRACKING_ID = 'G-EV56M7MMG5';
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: light)').matches || localStorage.getItem('light') === '1') setLight(true);
  }, []);

  useEffect(() => {
    function onAlert(data) {
      if (!data) return;
      data = JSON.parse(data);
      if (typeof data !== 'object') return;

      if (!data.alert && !!alert.content) return setAlert({});
      if (data.content === alert.content) return;
      return setAlert(data);
    }
    socket.on('alert', onAlert);
    return () => socket.off('alert', onAlert);
  }, [alert, setAlert]);

  useEffect(() => {
    socket.connect();
    return () => socket.disconnect();
  }, []);

  const changeTheme = () => {
    localStorage.setItem('light', !light ? 1 : 0);
    setLight(!light);
  };

  const props = {
    light,
    changeTheme,
    alert,
    cellNumber,
  };

  return (
    <div className={light ? 'light' : 'dark'}>
      <Routes>
        <Route path='/' element={<Home {...props} />} />
        <Route path='pricing' element={<Pricing {...props} />} />
        <Route path='portfolio' element={<Portfolio {...props} />} />
        <Route path='tos' element={<Home {...props} tos={true} />} />
        <Route path='cjsbirthday' element={<Birthday {...props} />} />
        <Route path='*' element={<Error404 {...props} />} />
      </Routes>
    </div>
  );
}
