import { Fragment } from 'react';
import { Carousel } from 'react-bootstrap';
import { testimonials } from '../../data/data.tsx';

export const Testimonials = (props) => {
  return (
    <Fragment>
      <h4 className='testimonial-heading'>What do my clients think of me?</h4>
      <hr />
      <Carousel variant={props.light ? 'dark' : 'light'} className='testimonials-carousel'>
        {testimonials.map((t) => (
          <Carousel.Item key={t.user} className='testimonial-item'>
            <h4>❝ {t.content} ❞</h4>
            <p>
              ~ <span className='testimonial-user'>{t.user}</span>
            </p>
          </Carousel.Item>
        ))}
      </Carousel>
    </Fragment>
  );
};
