import { useState, useEffect } from 'react';
import axios from 'axios';

export default function useHomeAlert(url) {
  const [alert, setAlert] = useState({});

  useEffect(() => {
    function makeRequest() {
      axios.get(`${url}/alert`).then((res) => {
        if (!res.data.alert && !!alert.content) return setAlert({});

        if (res.data.content === alert.content) return;

        setAlert(res.data);
      });
    }

    makeRequest();
  });

  return [alert, setAlert];
}
