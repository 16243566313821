import { useEffect, useState } from 'react';
import birthdayImage from '../images/birthday.jpg';
import verticalImage from '../images/cj-vertical.jpg';
import horizontalImage from '../images/cj-horizontal.jpg';
import '../stylesheets/birthday.scss';

const NUM_EMOJIS = 60;
const EMOJIS = ['cake', 'balloon', 'balloon-heart'];

export const Birthday = () => {
  const [emojiObjs, setEmojiObjs] = useState([]);

  useEffect(() => {
    const WIDTH = document.getElementById('birthdayContainer').offsetWidth;
    const HEIGHT = document.getElementById('birthdayContainer').offsetHeight;

    const elements = [];
    for (var i = 0; i < NUM_EMOJIS; i++) {
      const styling = {
        position: 'absolute',
        top: Math.random() * HEIGHT - 30,
        left: Math.random() * WIDTH - 30,
      };

      const randEmoji = EMOJIS[Math.floor(Math.random() * EMOJIS.length)];

      elements.push({ emoji: randEmoji, styling });
    }
    setEmojiObjs(elements);
  }, []);

  return (
    <>
      <div className='birthday-container'>
        <div className='text'>
          <h1>HAPPY 18TH CONNOR 😍</h1>
          <p></p>
        </div>
        <div className='image'>
          <img src={birthdayImage} alt='birthday' />
        </div>
      </div>
      <div className='birthday-images-container'>
        <div className='image1'>
          <img src={verticalImage} alt='vertical' />
        </div>
        <div className='image2'>
          <img src={horizontalImage} alt='horizontal' />
        </div>
      </div>
      <div id='birthdayContainer' className='birthday-emojis-container'>
        {emojiObjs.map((emojiObj, i) => (
          <i className={`bi bi-${emojiObj.emoji}`} key={i} style={emojiObj.styling} />
        ))}
      </div>
    </>
  );
};
