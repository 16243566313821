import { Card, Col } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import { InfoTooltip } from '../Utilities/InfoTooltip';

export const PortfolioExample = ({ light, p }) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <Col className={`example-col${inView ? ' in-view' : ''}`} ref={ref}>
      <div className='example-card-outer'>
        <Card className='example-card'>
          {p.svg ? <p.image className='card-img-top' light={light} /> : <Card.Img variant='top' src={p.image} />}
          <Card.Body className='example-body-flex'>
            <div>
              <Card.Title className='example-title'>{p.title}</Card.Title>
              <Card.Text className='example-text'>
                {p.description} {!!p.tooltip && <InfoTooltip light={light} text={p.tooltip} textAlign='left' />}
              </Card.Text>
            </div>
            {p.gitHubUrl && (
              <a href={p.gitHubUrl} alt='GitHub Link' className='example-github-link' target='_blank' rel='noreferrer'>
                <i className='bi bi-github github-icon' /> GitHub
              </a>
            )}
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
};
